<template>
  <div class="container">
    <div class="top">
      <!--      <el-input v-model="searchData.search" placeholder="请输入关键词进行搜索"></el-input>-->
      <!--      <el-button icon="el-icon-search" type="primary" @click="search">搜索</el-button>-->
      <el-select v-model="searchData.deviceTypeId" :placeholder="fun.getLanguageText($store.state.language, '请选择设备类型')"
        @change="changeSearchData($event, 1)">
        <el-option v-for="item in DeviceTypeList1" :key="item.id" :label="item.name" :value="item.id">
        </el-option>
      </el-select>
      <el-select v-model="searchData.deviceNameId" :placeholder="fun.getLanguageText($store.state.language, '请选择设备名称')"
        @change="changeSearchData($event, 2)">
        <el-option v-for="item in DeviceTypeList2" :key="item.id" :label="item.name" :value="item.id">
        </el-option>
      </el-select>
      <el-select v-model="searchData.deviceModelId" :placeholder="fun.getLanguageText($store.state.language, '请选择设备型号')"
        @change="changeSearchData($event, 3)">
        <el-option v-for="item in DeviceTypeList3" :key="item.id" :label="item.name" :value="item.id">
        </el-option>
      </el-select>
      <el-select v-model="searchData.sn" :placeholder="fun.getLanguageText($store.state.language, '请选择设备序列号')"
        @change="search">
        <el-option v-for="item in snList" :key="item.sn" :label="item.sn" :value="item.sn">
        </el-option>
      </el-select>
      <el-date-picker v-model="time" :picker-options="pickerOptions" align="right" class="time"
        :end-placeholder="fun.getLanguageText($store.state.language, '结束日期')" range-separator="~"
        :start-placeholder="fun.getLanguageText($store.state.language, '开始日期')" type="daterange" unlink-panels
        @change="change">
      </el-date-picker>
      <el-button icon="el-icon-search" style="margin-left: 20px" type="primary" @click="search">
        {{ fun.getLanguageText($store.state.language, '搜索') }}
      </el-button>
    </div>
    <el-tabs v-model="activeName">
      <el-tab-pane :label="fun.getLanguageText($store.state.language, '成本统计')" name="cost">
        <el-radio-group v-model="cost_value" size="mini" style="margin-bottom: 12px;margin-left: 1100px">
          <el-radio-button :label="fun.getLanguageText($store.state.language, '配方')"></el-radio-button>
          <el-radio-button :label="fun.getLanguageText($store.state.language, '时间')"></el-radio-button>
        </el-radio-group>
        <el-table v-show="is_table" :border="true" :data="cost" :max-height="$store.state.clientHeight" stripe
          style="width: 1280px">
          <el-table-column :label="fun.getLanguageText($store.state.language, '化料编号')" prop="id">
          </el-table-column>
          <el-table-column :label="fun.getLanguageText($store.state.language, '化料名称')" prop="medicamentName">
          </el-table-column>
          <el-table-column :label="fun.getLanguageText($store.state.language, '总量(升)')" prop="consume">
          </el-table-column>
          <el-table-column
            :label="fun.getLanguageText($store.state.language, '单价') + '(' + fun.getLanguageText($store.state.language, 'RMB/Liter') + ')'"
            prop="price">
          </el-table-column>
          <el-table-column :label="fun.getLanguageText($store.state.language, '总价')" prop="total">
          </el-table-column>
        </el-table>
        <div v-show="!is_table"
          :style="'height:' + ($store.state.clientHeight + 40) + 'px;overflow-x: hidden;overflow-y: scroll;width: 100%'">
          <template v-for="item in 2">
            <div v-if="item === 1">{{ fun.getLanguageText($store.state.language, '总量') }}</div>
            <div v-if="item === 2">{{ fun.getLanguageText($store.state.language, '总价') }}</div>
            <div :id="`cost_chart${item}`" :style="{ width: '1200px', height: '500px' }"></div>
          </template>

        </div>

      </el-tab-pane>

      <el-tab-pane :label="fun.getLanguageText($store.state.language, '化料统计')" name="chemicals">
        <el-radio-group v-model="chemicals_value" size="mini" style="margin-bottom: 12px;margin-left: 1100px">
          <el-radio-button :label="fun.getLanguageText($store.state.language, '配方')"></el-radio-button>
          <el-radio-button :label="fun.getLanguageText($store.state.language, '时间')"></el-radio-button>
        </el-radio-group>
        <el-table v-show="is_table" :data="chemicals" :max-height="$store.state.clientHeight" style="width: 100%">
          <el-table-column :label="fun.getLanguageText($store.state.language, '化料编号')" prop="id" width="150">
          </el-table-column>
          <el-table-column :label="fun.getLanguageText($store.state.language, '化料名称')" prop="medicamentName" width="150">
          </el-table-column>
          <el-table-column :label="fun.getLanguageText($store.state.language, '洗衣仓1')">
            <el-table-column :label="fun.getLanguageText($store.state.language, '次数')" prop="1.count" width="120">
            </el-table-column>
            <el-table-column :label="fun.getLanguageText($store.state.language, '总量(升)')" prop="1.consume_channel"
              width="120">
            </el-table-column>
          </el-table-column>
          <el-table-column :label="fun.getLanguageText($store.state.language, '洗衣仓2')">
            <el-table-column :label="fun.getLanguageText($store.state.language, '次数')" prop="2.count" width="120">
            </el-table-column>
            <el-table-column :label="fun.getLanguageText($store.state.language, '总量(升)')" prop="2.consume_channel"
              width="120">
            </el-table-column>
          </el-table-column>
          <el-table-column :label="fun.getLanguageText($store.state.language, '洗衣仓3')">
            <el-table-column :label="fun.getLanguageText($store.state.language, '次数')" prop="3.count" width="120">
            </el-table-column>
            <el-table-column :label="fun.getLanguageText($store.state.language, '总量(升)')" prop="3.consume_channel"
              width="120">
            </el-table-column>
          </el-table-column>
          <el-table-column :label="fun.getLanguageText($store.state.language, '洗衣仓4')">
            <el-table-column :label="fun.getLanguageText($store.state.language, '次数')" prop="4.count" width="120">
            </el-table-column>
            <el-table-column :label="fun.getLanguageText($store.state.language, '总量(升)')" prop="4.consume_channel"
              width="120">
            </el-table-column>
          </el-table-column>
          <el-table-column :label="fun.getLanguageText($store.state.language, '总次数')" prop="line_count" width="150">
          </el-table-column>
          <el-table-column :label="fun.getLanguageText($store.state.language, '总量(升)')" prop="consume_channel"
            width="150">
          </el-table-column>
        </el-table>
        <div v-show="!is_table"
          :style="'height:' + ($store.state.clientHeight + 40) + 'px;overflow-x: hidden;overflow-y: scroll;width: 100%'">
          <div>{{ fun.getLanguageText($store.state.language, '化料统计') }}</div>
          <div id="chemicals_chart_times" :style="{ width: '1200px', height: '500px' }"></div>
          <div id="chemicals_chart_total" :style="{ width: '1200px', height: '500px' }"></div>
        </div>
      </el-tab-pane>

      <el-tab-pane :style="'height:' + ($store.state.clientHeight + 40) + 'px;overflow-x: hidden;overflow-y: scroll;'"
        class="formula" :label="fun.getLanguageText($store.state.language, '配方统计')" name="formula">
        <el-table v-for="item in formula_table" v-if="is_table" :key="item.id" style="width: 100%">
          <el-table-column :label="fun.getLanguageText($store.state.language, `配方${item.numid}名称`)" align="center"
            prop="date" width="170">
            <!--            <el-table-column align="center" label="已执行次数" prop="date" width="150">-->
            <el-table-column align="center" :label="fun.getLanguageText($store.state.language, '洗衣龙/执行次数')" prop="date"
              width="170">
              <el-table-column align="center" :label="fun.getLanguageText($store.state.language, '化料名称')" prop="date"
                width="170">
                <el-table-column align="center" :label="fun.getLanguageText($store.state.language, '加药量设定（毫升）')"
                  prop="date" width="170">
                  <el-table-column align="center" :label="fun.getLanguageText($store.state.language, '加药次数')" prop="date"
                    width="170">
                    <el-table-column align="center" :label="fun.getLanguageText($store.state.language, '加药总量（升）')"
                      prop="date" width="170">
                      <el-table-column align="center" :label="fun.getLanguageText($store.state.language, '单价')"
                        prop="date" width="170">
                        <el-table-column align="center" :label="fun.getLanguageText($store.state.language, '总价')"
                          prop="date" width="170">
                        </el-table-column>
                      </el-table-column>
                    </el-table-column>
                  </el-table-column>
                </el-table-column>
              </el-table-column>
              <!--              </el-table-column>-->
            </el-table-column>
          </el-table-column>
          <el-table-column :label="item.recipe_name" align="center" prop="date">
            <el-table-column
              :label="`${fun.getLanguageText($store.state.language, '洗衣龙仓')}1/(${item.medicamentName_1_times}${fun.getLanguageText($store.state.language, '次')})`"
              align="center" prop="name">
              <el-table-column :label="item.medicamentName_1" align="center" prop="name">
                <el-table-column :label="item.medicamentName_1_volume" align="center" prop="name">
                  <el-table-column :label="item.medicamentName_1_times" align="center" prop="name">
                    <el-table-column :label="item.medicamentName_1_total" align="center" prop="name">
                      <el-table-column :label="item.medicamentName_1_price" align="center" prop="name">
                        <el-table-column :label="item.medicamentName_1_totalPrice" align="center" prop="name">
                        </el-table-column>
                      </el-table-column>
                    </el-table-column>
                  </el-table-column>
                </el-table-column>
              </el-table-column>
              <el-table-column :label="item.medicamentName_2" align="center" prop="name">
                <el-table-column :label="item.medicamentName_2_volume" align="center" prop="name">
                  <el-table-column :label="item.medicamentName_2_times" align="center" prop="name">
                    <el-table-column :label="item.medicamentName_2_total" align="center" prop="name">
                      <el-table-column :label="item.medicamentName_2_price" align="center" prop="name">
                        <el-table-column :label="item.medicamentName_2_totalPrice" align="center" prop="name">
                        </el-table-column>
                      </el-table-column>
                    </el-table-column>
                  </el-table-column>
                </el-table-column>
              </el-table-column>
              <el-table-column :label="item.medicamentName_3" align="center" prop="name">
                <el-table-column :label="item.medicamentName_3_volume" align="center" prop="name">
                  <el-table-column :label="item.medicamentName_3_times" align="center" prop="name">
                    <el-table-column :label="item.medicamentName_3_total" align="center" prop="name">
                      <el-table-column :label="item.medicamentName_3_price" align="center" prop="name">
                        <el-table-column :label="item.medicamentName_3_totalPrice" align="center" prop="name">
                        </el-table-column>
                      </el-table-column>
                    </el-table-column>
                  </el-table-column>
                </el-table-column>
              </el-table-column>
            </el-table-column>
            <el-table-column
              :label="`${fun.getLanguageText($store.state.language, '洗衣龙仓')}2/(${item.medicamentName_4_times}${fun.getLanguageText($store.state.language, '次')})`"
              align="center" prop="name">
              <el-table-column :label="item.medicamentName_4" align="center" prop="name">
                <el-table-column :label="item.medicamentName_4_volume" align="center" prop="name">
                  <el-table-column :label="item.medicamentName_4_times" align="center" prop="name">
                    <el-table-column :label="item.medicamentName_4_total" align="center" prop="name">
                      <el-table-column :label="item.medicamentName_4_price" align="center" prop="name">
                        <el-table-column :label="item.medicamentName_4_totalPrice" align="center" prop="name">
                        </el-table-column>
                      </el-table-column>
                    </el-table-column>
                  </el-table-column>
                </el-table-column>
              </el-table-column>
              <el-table-column :label="item.medicamentName_5" align="center" prop="name">
                <el-table-column :label="item.medicamentName_5_volume" align="center" prop="name">
                  <el-table-column :label="item.medicamentName_5_times" align="center" prop="name">
                    <el-table-column :label="item.medicamentName_5_total" align="center" prop="name">
                      <el-table-column :label="item.medicamentName_5_price" align="center" prop="name">
                        <el-table-column :label="item.medicamentName_5_totalPrice" align="center" prop="name">
                        </el-table-column>
                      </el-table-column>
                    </el-table-column>
                  </el-table-column>
                </el-table-column>
              </el-table-column>
              <el-table-column :label="item.medicamentName_6" align="center" prop="name">
                <el-table-column :label="item.medicamentName_6_volume" align="center" prop="name">
                  <el-table-column :label="item.medicamentName_6_times" align="center" prop="name">
                    <el-table-column :label="item.medicamentName_6_total" align="center" prop="name">
                      <el-table-column :label="item.medicamentName_6_price" align="center" prop="name">
                        <el-table-column :label="item.medicamentName_6_totalPrice" align="center" prop="name">
                        </el-table-column>
                      </el-table-column>
                    </el-table-column>
                  </el-table-column>
                </el-table-column>
              </el-table-column>
            </el-table-column>
            <el-table-column
              :label="`${fun.getLanguageText($store.state.language, '洗衣龙仓')}3/(${item.medicamentName_7_times}${fun.getLanguageText($store.state.language, '次')})`"
              align="center" prop="name">
              <el-table-column :label="item.medicamentName_7" align="center" prop="name">
                <el-table-column :label="item.medicamentName_7_volume" align="center" prop="name">
                  <el-table-column :label="item.medicamentName_7_times" align="center" prop="name">
                    <el-table-column :label="item.medicamentName_7_total" align="center" prop="name">
                      <el-table-column :label="item.medicamentName_7_price" align="center" prop="name">
                        <el-table-column :label="item.medicamentName_7_totalPrice" align="center" prop="name">
                        </el-table-column>
                      </el-table-column>
                    </el-table-column>
                  </el-table-column>
                </el-table-column>
              </el-table-column>
              <el-table-column :label="item.medicamentName_8" align="center" prop="name">
                <el-table-column :label="item.medicamentName_8_volume" align="center" prop="name">
                  <el-table-column :label="item.medicamentName_8_times" align="center" prop="name">
                    <el-table-column :label="item.medicamentName_8_total" align="center" prop="name">
                      <el-table-column :label="item.medicamentName_8_price" align="center" prop="name">
                        <el-table-column :label="item.medicamentName_8_totalPrice" align="center" prop="name">
                        </el-table-column>
                      </el-table-column>
                    </el-table-column>
                  </el-table-column>
                </el-table-column>
              </el-table-column>
            </el-table-column>
            <el-table-column
              :label="`${fun.getLanguageText($store.state.language, '洗衣龙仓')}4/(${item.medicamentName_9_times}${fun.getLanguageText($store.state.language, '次')})`"
              align="center" prop="name">
              <el-table-column :label="item.medicamentName_9" align="center" prop="name">
                <el-table-column :label="item.medicamentName_9_volume" align="center" prop="name">
                  <el-table-column :label="item.medicamentName_9_times" align="center" prop="name">
                    <el-table-column :label="item.medicamentName_9_total" align="center" prop="name">
                      <el-table-column :label="item.medicamentName_9_price" align="center" prop="name">
                        <el-table-column :label="item.medicamentName_9_totalPrice" align="center" prop="name">
                        </el-table-column>
                      </el-table-column>
                    </el-table-column>
                  </el-table-column>
                </el-table-column>
              </el-table-column>
              <el-table-column :label="item.medicamentName_10" align="center" prop="name">
                <el-table-column :label="item.medicamentName_10_volume" align="center" prop="name">
                  <el-table-column :label="item.medicamentName_10_times" align="center" prop="name">
                    <el-table-column :label="item.medicamentName_10_total" align="center" prop="name">
                      <el-table-column :label="item.medicamentName_10_price" align="center" prop="name">
                        <el-table-column :label="item.medicamentName_10_totalPrice" align="center" prop="name">
                        </el-table-column>
                      </el-table-column>
                    </el-table-column>
                  </el-table-column>
                </el-table-column>
              </el-table-column>
            </el-table-column>
          </el-table-column>
        </el-table>
        <div v-show="!is_table"
          :style="'height:' + ($store.state.clientHeight + 40) + 'px;overflow-x: hidden;overflow-y: scroll;width: 100%'">
          <div>{{ fun.getLanguageText($store.state.language, '加药次数') }}</div>
          <div :id="`chemicals_formula_1`" :style="{ width: '1200px', height: '600px' }"></div>
          <div>{{ fun.getLanguageText($store.state.language, '化料总量') }}</div>
          <div :id="`chemicals_formula_2`" :style="{ width: '1200px', height: '600px' }"></div>
          <div>{{ fun.getLanguageText($store.state.language, '总价') }}</div>
          <div :id="`chemicals_formula_3`" :style="{ width: '1200px', height: '600px' }"></div>
        </div>
      </el-tab-pane>

      <el-tab-pane :label="fun.getLanguageText($store.state.language, '报警信息')" name="warning">
        <el-table :data="warnData" :max-height="$store.state.clientHeight" style="width: 100%">
          <el-table-column :label="fun.getLanguageText($store.state.language, '报警信息种类')" prop="name" width="200">
          </el-table-column>
          <el-table-column :label="fun.getLanguageText($store.state.language, '洗衣龙仓1')" prop="1">
          </el-table-column>
          <el-table-column :label="fun.getLanguageText($store.state.language, '洗衣龙仓2')" prop="2">
          </el-table-column>
          <el-table-column :label="fun.getLanguageText($store.state.language, '洗衣龙仓3')" prop="3">
          </el-table-column>
          <el-table-column :label="fun.getLanguageText($store.state.language, '洗衣龙仓4')" prop="4">
          </el-table-column>
        </el-table>
      </el-tab-pane>

      <!--      <el-tab-pane class="device" label="设备统计" name="device">
              <div class="header">
                <div class="header-c1 flex-center" style="flex: 1">配方</div>
                <div class="header-c1 flex-center" style="flex: 1">数量</div>
                <div class="header-c1" style="flex: 1">
                  <div class="flex-center" style="border-bottom: 1px solid rgb(235, 238, 245);">通道</div>
                  <div class="flex-center">泵</div>
                </div>
                <div class="header-c1" style="flex: 3">
                  <div class="flex-center" style="border-bottom: 1px solid rgb(235, 238, 245);">通道</div>
                  <div class="header-c2">
                    <div>{{ device_info.medicamentName_1 }}</div>
                    <div>{{ device_info.medicamentName_2 }}</div>
                    <div style="border-right: 0">{{ device_info.medicamentName_3 }}</div>
                  </div>
                </div>
                <div class="header-c1" style="flex: 3">
                  <div class="flex-center" style="border-bottom: 1px solid rgb(235, 238, 245);">通道</div>
                  <div>
                    <div>{{ device_info.medicamentName_4 }}</div>
                    <div>{{ device_info.medicamentName_5 }}</div>
                    <div style="border-right: 0">{{ device_info.medicamentName_6 }}</div>
                  </div>
                </div>
                <div class="header-c1" style="flex: 2">
                  <div class="flex-center" style="border-bottom: 1px solid rgb(235, 238, 245);">通道</div>
                  <div>
                    <div>{{ device_info.medicamentName_7 }}</div>
                    <div style="border-right: 0">{{ device_info.medicamentName_8 }}</div>
                  </div>
                </div>
                <div class="header-c1" style="flex: 2">
                  <div class="flex-center" style="border-bottom: 1px solid rgb(235, 238, 245);">通道</div>
                  <div>
                    <div>{{ device_info.medicamentName_9 }}</div>
                    <div style="border-right: 0">{{ device_info.medicamentName_10 }}</div>
                  </div>
                </div>
              </div>
              <div :style="'height:' + ($store.state.clientHeight-60)+'px;'" class="table-scroll">
                <div v-for="itemall in device" :key="itemall.recipe_name" class="table">
                  <div class="table-c1 flex-center"
                       style="flex: 1;border-bottom: 1px solid rgb(235, 238, 245);border-left: 1px solid rgb(235, 238, 245);">
                    {{ itemall.recipe_name }}
                  </div>
                  <div class="table-c1 flex-center" style="flex: 1;border-bottom: 1px solid rgb(235, 238, 245);">未知</div>
                  <div class="table-c1" style="flex: 1">
                    <div class="table-c2">设定用量(ml)</div>
                    <div class="table-c2">总量(Liter)</div>
                    <div class="table-c2">单价(RMB/Liter)</div>
                    <div class="table-c2">总价</div>
                  </div>
                  <div v-for="item in 10" :key="item" class="table-c1">
                    <div class="table-c2">{{ itemall[`medicamentName_${item}_volume`] }}</div>
                    <div class="table-c2">{{ itemall[`medicamentName_${item}_total`] }}</div>
                    <div class="table-c2">{{ itemall[`price_${item}`] }}</div>
                    <div class="table-c2">{{ itemall[`medicamentName_${item}_total_money`] }}</div>
                  </div>

                </div>
              </div>


            </el-tab-pane>-->
      <el-tab-pane :label="fun.getLanguageText($store.state.language, '参数变更记录')" name="change">
        <el-table :data="changeData" :max-height="$store.state.clientHeight" stripe style="width: 100%">
          <el-table-column :label="fun.getLanguageText($store.state.language, '序号')" prop="id" width="120">
          </el-table-column>
          <el-table-column :label="fun.getLanguageText($store.state.language, '操作时间')" prop="created_at" width="250">
          </el-table-column>
          <el-table-column :label="fun.getLanguageText($store.state.language, '操作账号')" prop="user">
          </el-table-column>
          <el-table-column :label="fun.getLanguageText($store.state.language, '设备种类')" prop="deviceType">
          </el-table-column>
          <el-table-column :label="fun.getLanguageText($store.state.language, '设备名称')" prop="deviceName">
          </el-table-column>
          <el-table-column :label="fun.getLanguageText($store.state.language, '设备型号')" prop="deviceModel">
          </el-table-column>
          <el-table-column :label="fun.getLanguageText($store.state.language, '设备序列号')" prop="sn">
          </el-table-column>
          <el-table-column :label="fun.getLanguageText($store.state.language, '变更内容')" min-width="260" prop="content">
            <template slot-scope="scope">
            <span>{{ fun.getLanguageText($store.state.language, scope.row.content) }}</span>
        </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>
    </el-tabs>

    <div style="position: absolute;right: 0;top: 60px;display: flex;align-items: center">
      <div class="ab">
        {{ fun.getLanguageText($store.state.language, '机器序列号') }}:{{ searchData.sn }}
      </div>
      <div class="ab" style="margin-left: 16px">{{ fun.getLanguageText($store.state.language, '公司名称') }}:{{
        company
      }}
      </div>
      <el-button class="ab" size="small" style="margin-left: 32px" type="primary" @click="handleDownload">
        {{ fun.getLanguageText($store.state.language, '导出报表') }}
      </el-button>
      <el-button :disabled="is_table" class="ab" size="small" style="" type="primary" @click="is_table = true">
        {{ fun.getLanguageText($store.state.language, '表格') }}
      </el-button>
      <el-button :disabled="!is_table" class="ab" size="small" style="" type="primary" @click="is_table = false">
        {{ fun.getLanguageText($store.state.language, '统计') }}
      </el-button>
    </div>

  </div>
</template>
<script>
// import XLSX from "xlsx";
// 引入基本模板
let echarts = require('echarts/lib/echarts')
// 引入柱状图组件
require('echarts/lib/chart/bar')
// 引入legend组件
require("echarts/lib/component/legend");
// 引入提示框和title组件
require('echarts/lib/component/tooltip')

require('echarts/lib/component/title')
const posList = [
  'left', 'right', 'top', 'bottom',
  'inside',
  'insideTop', 'insideLeft', 'insideRight', 'insideBottom',
  'insideTopLeft', 'insideTopRight', 'insideBottomLeft', 'insideBottomRight'
];

const labelOption = {
  show: false,
  distance: {
    min: 0,
    max: 100
  },
  align: {
    options: {
      left: 'left',
      center: 'center',
      right: 'right'
    }
  },
  verticalAlign: {
    options: {
      top: 'top',
      middle: 'middle',
      bottom: 'bottom'
    }
  },
  rotate: {
    min: -90,
    max: 90
  },
  formatter: '{c}  {name|{a}}',
  fontSize: 16,
  rich: {
    name: {
      textBorderColor: '#fff'
    }
  }
};
export default {
  data() {
    return {
      company: '',// 公司名称
      is_table: true,//是否table 列表
      chemicals_chart: [1, 2, 3, 4],//化料统计表格数组
      chemicals_chart_1: [1, 2, 3, 4], // 化料统计次数数组
      chemicals_formula_1: [],
      chemicals_formula_2: [],
      chemicals_formula_3: [],
      pickerOptions: { // 限制时间不让选择今天以前的
        disabledDate(time) {
          return time.getTime() > Date.now()
        }
      },

      // 搜索处理*********
      searchData: {
        sn: '',
        search: '',
        deviceTypeId: '',
        deviceNameId: '',
        deviceModelId: '',
        start: '',
        end: ''
      },
      DeviceTypeList1: [],
      DeviceType1: 0,
      DeviceTypeList2: [],
      DeviceType2: 0,
      DeviceTypeList3: [],
      DeviceType3: 0,
      // ***********************
      chart: '',
      time: '',
      activeName: "cost",
      router: false,
      input: '',
      user_type: '',
      tableData: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 20],
      warnData: [],
      cost: [], // 成本统计列表
      chemicals: [], // 化料统计列表
      formula: [], // 配方统计列表
      formula_table: [], // 配方统计列表 ---- 表格
      device: [], // 设备统计列表
      device_info: {}, // 设备统计总计列表
      snList: [], // 序列号列表
      changeData: [],// 参数变更记录
      cost_value: this.fun.getLanguageText(this.$store.state.language, '时间'), // 成本统计模式
      chemicals_value: this.fun.getLanguageText(this.$store.state.language, '时间'),// 化料统计模式
      systemInfo: {}, // 系统信息
    }
  },
  created() {
    if (this.$route.query.sn) {
      this.searchData.sn = this.$route.query.sn
      this.$nextTick(() => {
        this.search()
      })
    }
    const end = new Date();
    const start = new Date();
    start.setTime(start.getTime() - 3600 * 1000 * 24 * 10 * 40);
    this.time = [start, end]
    this.searchData.start = parseInt((start).valueOf() / 1000)
    this.searchData.end = parseInt((end).valueOf() / 1000)
    this.statistics()
    this.getDeviceTypeList(1)
  },
  methods: {
    // 获取系统信息
    // 读取安装信息->installInfo
    async get_system_info() {
      let res = await this.$http.post('device/system-info', {
        sn: this.searchData.sn,
      })
      res = res.data
      if (res.code !== 2000) {
        this.$message.error(res.msg);
        console.log('读取异常', res)
        return;
      }
      this.systemInfo = res.data
    },
    // 获取下拉列表----sn
    dropdown() {
      this.$http.post('device/dropdown-list', this.searchData)
        .then(res => {
          console.log(res)
          if (res.data.code === 2000) {
            this.snList = res.data.data
          }
        })
    },
    // 搜素
    search() {
      // if (this.activeName === 'cost') {
      //   this.statistics_cost()
      // } else if (this.activeName === 'warning') {
      //   this.statistics()
      // } else if (this.activeName === 'chemicals') {
      //   this.statistics_chemicals()
      // } else if (this.activeName === 'formula') {
      //   this.statistics_formula()
      // } else if (this.activeName === 'device') {
      //   this.statistics_device()
      // }
      if(this.cost_value === "配方"){
        this.statistics_cost_recipe()
      }else{
        this.statistics_cost()
      }
      if(this.chemicals_value == "配方"){
        this.statistics_chemicals_recipe()
      }else{
        this.statistics_chemicals()
      }
      this.statistics()
      this.statistics_formula()
      this.statistics_device()
      this.getChangeList()
      this.get_system_info()
    },
    // 下拉选择框被改变
    changeSearchData(e, id) {
      this.searchData.sn = ''
      console.log(e, id)
      if (id === 1) {
        this.DeviceType1 = e
        this.searchData.deviceNameId = ''
        this.searchData.deviceModelId = ''
        this.DeviceTypeList2 = []
        this.DeviceTypeList3 = []
        this.getDeviceTypeList(2, e)
      }
      if (id === 2) {
        this.DeviceType2 = e
        this.searchData.deviceModelId = ''
        this.DeviceTypeList3 = []
        this.getDeviceTypeList(3, e)
      }
      if (id === 3) {
        this.DeviceType3 = e
      }
      //
      // this.search()
      this.dropdown()
    },
    // 获取设备类型列表
    getDeviceTypeList(type, pid) { // 1:类型 2:名称 3型号
      this.$http.post('device-type/list', {
        type,
        pid
      }).then(res => {
        if (res.data.code === 2000) {
          if (type == 1) {
            this.DeviceTypeList1 = res.data.data
          }
          if (type == 2) {
            this.DeviceTypeList2 = res.data.data
          }
          if (type == 3) {
            this.DeviceTypeList3 = res.data.data
          }
        }
        console.log(res)
      })
    },
    // 成本-配方统计
    async statistics_cost_recipe() {
      let data = {}
      // data.sn = this.router.query.sn
      data = {
        sn: this.searchData.sn,
        start: this.searchData.start,
        end: this.searchData.end,
      }
      let res = await this.$http.post('statistics/cost-recipe', data)
      console.log(res)
      if (res.data.code !== 2000) {
        console.log('异常-statistics_cost_recipe', res.data)
        this.$message({
          message: res.data.msg,
          type: 'warning'
        });
        return
      }
      let list = []
      res = res.data.data
      for (let i = 0; i < 11; i++) {
        // console.log(res[i])
        if (i + 1 === 11) {
          res[i + 1].id = this.fun.getLanguageText(this.$store.state.language, '总计')
        } else {
          res[i + 1].id = i + 1
        }
        list.push(res[i + 1])
      }
      this.cost = list
      let xAxis = []
      let series = []
      let series_price = []
      let series_total = []
      list.map(item => {
        series.push(item.consume)
        series_price.push(item.price)
        series_total.push(item.total)
      })

      list.map(item => {
        if (item.id === this.fun.getLanguageText(this.$store.state.language, '总计')) {
          xAxis.push(item.id)
        } else {
          xAxis.push(item.medicamentName)
        }

      })
      console.log(list)
      let chart1 = echarts.init(document.getElementById(`cost_chart1`));
      chart1.setOption({
        color: ['#6b9cd4', '#deab8a', '#817936', '#444693', '#d71345', '#843900', '#bed742', '#121a2a', '#78cdd1', '#009ad6', '#145b7d', '#11264f', '#c99979', '#840228', '#7d5886', '#bb505d', '#0c212b', '#494e8f'],
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        legend: {
          data: ['Forest', 'Steppe', 'Desert', 'Wetland']
        },
        toolbox: {
          show: true,
          orient: 'vertical',
          left: 'right',
          top: 'center',
          feature: {
            mark: {
              show: true
            },
            dataView: {
              show: true,
              readOnly: false
            },
            magicType: {
              show: true,
              type: ['line', 'bar', 'stack', 'tiled']
            },
            restore: {
              show: true
            },
            saveAsImage: {
              show: true
            }
          }
        },
        xAxis: [{
          type: 'category',
          axisTick: {
            show: false
          },
          data: xAxis
        }],
        yAxis: [{
          type: 'value',
          axisLabel: {
            formatter: '{value} L'
          }
        }],
        series: [{
          name: this.fun.getLanguageText(this.$store.state.language, '总量'),
          type: 'bar',
          barGap: 0,
          label: labelOption,
          data: series
        }
        ]
      })
      let chart2 = echarts.init(document.getElementById(`cost_chart2`));
      chart2.setOption({
        color: ['#6b9cd4'],
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        legend: {
          data: ['Forest', 'Steppe', 'Desert', 'Wetland']
        },
        toolbox: {
          show: true,
          orient: 'vertical',
          left: 'right',
          top: 'center',
          feature: {
            mark: {
              show: true
            },
            dataView: {
              show: true,
              readOnly: false
            },
            magicType: {
              show: true,
              type: ['line', 'bar', 'stack', 'tiled']
            },
            restore: {
              show: true
            },
            saveAsImage: {
              show: true
            }
          }
        },
        xAxis: [{
          type: 'category',
          axisTick: {
            show: false
          },
          data: xAxis
        }],
        yAxis: [{
          type: 'value',
          axisLabel: {
            formatter: '{value} ' + this.fun.getLanguageText(this.$store.state.language, '元')
          }
        }],
        series: [{
          name: this.fun.getLanguageText(this.$store.state.language, '总价'),
          type: 'bar',
          barGap: 0,
          label: labelOption,
          data: series_total
        }
        ]
      })
    },
    // 成本统计
    async statistics_cost() {
      let data = {}
      // data.sn = this.router.query.sn
      data = {
        sn: this.searchData.sn,
        start: this.searchData.start,
        end: this.searchData.end,
      }
      let res = await this.$http.post('statistics/cost', data)
      console.log(res)
      if (res.data.code !== 2000) {
        console.log('异常-statistics_cost', res.data)
        this.$message({
          message: res.data.msg,
          type: 'warning'
        });
        return
      }
      let list = []
      res = res.data.data
      for (let i = 0; i < 11; i++) {
        // console.log(res[i])
        if (i + 1 === 11) {
          res[i + 1].id = this.fun.getLanguageText(this.$store.state.language, '总计')
        } else {
          res[i + 1].id = i + 1
        }
        list.push(res[i + 1])
      }
      this.cost = list
      let xAxis = []
      let series = []
      let series_price = []
      let series_total = []
      list.map(item => {
        series.push(item.consume)
        series_price.push(item.price)
        series_total.push(item.total)
      })

      list.map(item => {
        if (item.id === this.fun.getLanguageText(this.$store.state.language, '总计')) {
          xAxis.push(item.id)
        } else {
          xAxis.push(item.medicamentName)
        }

      })
      console.log(list)
      let chart1 = echarts.init(document.getElementById(`cost_chart1`));
      chart1.setOption({
        color: ['#6b9cd4', '#deab8a', '#817936', '#444693', '#d71345', '#843900', '#bed742', '#121a2a', '#78cdd1', '#009ad6', '#145b7d', '#11264f', '#c99979', '#840228', '#7d5886', '#bb505d', '#0c212b', '#494e8f'],
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        legend: {
          data: ['Forest', 'Steppe', 'Desert', 'Wetland']
        },
        toolbox: {
          show: true,
          orient: 'vertical',
          left: 'right',
          top: 'center',
          feature: {
            mark: {
              show: true
            },
            dataView: {
              show: true,
              readOnly: false
            },
            magicType: {
              show: true,
              type: ['line', 'bar', 'stack', 'tiled']
            },
            restore: {
              show: true
            },
            saveAsImage: {
              show: true
            }
          }
        },
        xAxis: [{
          type: 'category',
          axisTick: {
            show: false
          },
          data: xAxis
        }],
        yAxis: [{
          type: 'value',
          axisLabel: {
            formatter: '{value} L'
          }
        }],
        series: [{
          name: this.fun.getLanguageText(this.$store.state.language, '总量'),
          type: 'bar',
          barGap: 0,
          label: labelOption,
          data: series
        }
        ]
      })
      let chart2 = echarts.init(document.getElementById(`cost_chart2`));
      chart2.setOption({
        color: ['#6b9cd4'],
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        legend: {
          data: ['Forest', 'Steppe', 'Desert', 'Wetland']
        },
        toolbox: {
          show: true,
          orient: 'vertical',
          left: 'right',
          top: 'center',
          feature: {
            mark: {
              show: true
            },
            dataView: {
              show: true,
              readOnly: false
            },
            magicType: {
              show: true,
              type: ['line', 'bar', 'stack', 'tiled']
            },
            restore: {
              show: true
            },
            saveAsImage: {
              show: true
            }
          }
        },
        xAxis: [{
          type: 'category',
          axisTick: {
            show: false
          },
          data: xAxis
        }],
        yAxis: [{
          type: 'value',
          axisLabel: {
            formatter: '{value} ' + this.fun.getLanguageText(this.$store.state.language, '元')
          }
        }],
        series: [{
          name: this.fun.getLanguageText(this.$store.state.language, '总价'),
          type: 'bar',
          barGap: 0,
          label: labelOption,
          data: series_total
        }
        ]
      })

    },
    // 告警统计
    async statistics() {
      let data = {}
      // data.sn = this.router.query.sn
      data = {
        sn: this.searchData.sn,
        start: this.searchData.start,
        end: this.searchData.end,
      }
      let res = await this.$http.post('warn/statistics', data)
      if (res.data.code !== 2000) {
        console.log('异常', res.data)
        return
      }
      let list = []

      res.data.data[1].name = this.fun.getLanguageText(this.$store.state.language, "信号时间过长")
      res.data.data[2].name = this.fun.getLanguageText(this.$store.state.language, "信号时间过短")
      res.data.data[3].name = this.fun.getLanguageText(this.$store.state.language, "信号间隔过短")
      // alert( res.data.data[1].name)
      list.push(res.data.data[1])
      list.push(res.data.data[2])
      list.push(res.data.data[3])
      this.warnData = list
      console.log(list)
    },
    // 化料统计
    async statistics_chemicals() {
      let data = {}
      data = {
        sn: this.searchData.sn,
        start: this.searchData.start,
        end: this.searchData.end,
      }
      let res = await this.$http.post('statistics/chemicals', data)
      if (res.data.code !== 2000) {
        console.log('异常-statistics_chemicals', res.data)
        this.$message({
          message: res.data.msg,
          type: 'warning'
        });
        return
      }
      let list = []
      res = res.data.data
      for (let i = 0; i < 11; i++) {
        // console.log(res[i])
        if (i + 1 === 11) {
          res[i + 1].id = this.fun.getLanguageText(this.$store.state.language, '总计')
        } else {
          res[i + 1].id = i + 1
          // res[i + 1][]
        }
        list.push(res[i + 1])
      }
      this.chemicals = list
      console.log(list)
      let xAxis = {
        1: [],
        2: [],
        3: [],
        4: []
      }
      let series_count = {
        1: [],
        2: [],
        3: [],
        4: []
      }
      let series_consume_channel = {
        1: [],
        2: [],
        3: [],
        4: []
      }
      for (let i = 1; i < 5; i++) {

        list.map(item => {
          if (item.id !== this.fun.getLanguageText(this.$store.state.language, '总计')) {
            series_count[i].push(item[i].count)
            series_consume_channel[i].push(item[i].consume_channel)
          }

        })
        list.map(item => {
          if (item.id === this.fun.getLanguageText(this.$store.state.language, '总计')) {
            // xAxis[i].push(item.id)
          } else {
            xAxis[i].push(item.medicamentName)
          }
        })
      }
      console.log(xAxis)
      console.log(series_count)
      console.log(series_consume_channel)
      let chemicals_chart_times
      let chemicals_chart_total
      chemicals_chart_times = echarts.init(document.getElementById("chemicals_chart_times"));
      chemicals_chart_total = echarts.init(document.getElementById("chemicals_chart_total"));
      chemicals_chart_times.setOption({
        color: ['#53a1d9', '#ea7b2d', '#ada3a4', '#ffbd03', '#6cc7e1', '#f3ab63', '#aec8ee', '#6b9cd4', '#de7872', '#b7d37c', '#145b7d', '#11264f', '#c99979', '#840228', '#7d5886', '#bb505d', '#0c212b', '#494e8f'],
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        legend: {
          data: [this.fun.getLanguageText(this.$store.state.language, '洗衣仓一'), this.fun.getLanguageText(this.$store.state.language, '洗衣仓二'), this.fun.getLanguageText(this.$store.state.language, '洗衣仓三'), this.fun.getLanguageText(this.$store.state.language, '洗衣仓四')],
        },
        xAxis: [{
          type: 'category',

          data: xAxis[1]
        }],
        yAxis: [{
          type: 'value',
          name: this.fun.getLanguageText(this.$store.state.language, 'Counts'),
          axisLabel: {
            formatter: '{value} ' + this.fun.getLanguageText(this.$store.state.language, '次')
          }
        }],
        series: [
          {
            name: this.fun.getLanguageText(this.$store.state.language, '洗衣仓一'),
            type: 'bar',
            data: series_count[1]
          },
          {
            name: this.fun.getLanguageText(this.$store.state.language, '洗衣仓二'),
            type: 'bar',
            data: series_count[2]
          },
          {
            name: this.fun.getLanguageText(this.$store.state.language, '洗衣仓三'),
            type: 'bar',
            data: series_count[3]
          },
          {
            name: this.fun.getLanguageText(this.$store.state.language, '洗衣仓四'),
            type: 'bar',
            data: series_count[4]
          }
        ]
      })
      chemicals_chart_total.setOption({
        color: ['#53a1d9', '#ea7b2d', '#ada3a4', '#ffbd03', '#f3ab63', '#aec8ee', '#6b9cd4', '#de7872', '#b7d37c', '#11264f', '#c99979', '#840228', '#7d5886', '#bb505d', '#0c212b', '#494e8f'],
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        legend: {
          data: [this.fun.getLanguageText(this.$store.state.language, '洗衣仓一'), this.fun.getLanguageText(this.$store.state.language, '洗衣仓二'), this.fun.getLanguageText(this.$store.state.language, '洗衣仓三'), this.fun.getLanguageText(this.$store.state.language, '洗衣仓四')],
        },
        toolbox: {
          show: true,
          orient: 'vertical',
          left: 'right',
          top: 'center',
          feature: {
            mark: {
              show: true
            },
            dataView: {
              show: true,
              readOnly: false
            },
            restore: {
              show: true
            },
            saveAsImage: {
              show: true
            }
          }
        },
        xAxis: [{
          type: 'category',
          axisTick: {
            show: false
          },
          data: xAxis[1]
        }],
        yAxis: [{
          type: 'value',
          axisLabel: {
            formatter: '{value} ' + this.fun.getLanguageText(this.$store.state.language, '升'),
          }
        }],
        series: [
          //     {
          //   name: '次数',
          //   type: 'bar',
          //   barGap: 0,
          //   label: labelOption,
          //   data: series_count
          // },
          {
            name: this.fun.getLanguageText(this.$store.state.language, '洗衣仓一'),
            type: 'bar',
            barGap: 0,
            label: labelOption,
            data: series_consume_channel[1]
          },
          {
            name: this.fun.getLanguageText(this.$store.state.language, '洗衣仓二'),
            type: 'bar',
            label: labelOption,
            data: series_consume_channel[2]
          },
          {
            name: this.fun.getLanguageText(this.$store.state.language, '洗衣仓三'),
            type: 'bar',
            label: labelOption,
            data: series_consume_channel[3]
          },
          {
            name: this.fun.getLanguageText(this.$store.state.language, '洗衣仓四'),
            type: 'bar',
            label: labelOption,
            data: series_consume_channel[4]
          }
        ]
      })
      return
      for (let i = 1; i < 5; i++) {
        let xAxis = []
        let series_count = []
        let series_consume_channel = []
        list.map(item => {
          if (item.id === "总计") {
            // xAxis.push(item.id)
          } else {
            series_count.push(item[i].count)
            series_consume_channel.push(item[i].consume_channel)
          }

        })
        list.map(item => {
          if (item.id === "总计") {
            // xAxis.push(item.id)
          } else {
            xAxis.push(item.medicamentName)
          }
        })
        this.chemicals_chart[i] = echarts.init(document.getElementById(`chemicals_chart${i}`));
        this.chemicals_chart_1[i] = echarts.init(document.getElementById(`chemicals_chart_1${i}`));
        // console.log(series_count,xAxis,`chemicals_chart${i}`,document.getElementById(`chemicals_chart${i}`))
        this.chemicals_chart[i].setOption({
          color: ['#33FF00'],
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow'
            }
          },
          legend: {
            data: ['Forest', 'Steppe', 'Desert', 'Wetland']
          },
          toolbox: {
            show: true,
            orient: 'vertical',
            left: 'right',
            top: 'center',
            feature: {
              mark: {
                show: true
              },
              dataView: {
                show: true,
                readOnly: false
              },
              magicType: {
                show: true,
                type: ['line', 'bar', 'stack', 'tiled']
              },
              restore: {
                show: true
              },
              saveAsImage: {
                show: true
              }
            }
          },
          xAxis: [{
            type: 'category',
            axisTick: {
              show: false
            },
            data: xAxis
          }],
          yAxis: [{
            type: 'value',
            axisLabel: {
              formatter: '{value} 次'
            }
          }],
          series: [{
            name: '次数',
            type: 'bar',
            barGap: 0,
            label: labelOption,
            data: series_count
          }
          ]
        })
        this.chemicals_chart_1[i].setOption({
          color: ['#FFFF00'],
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow'
            }
          },
          legend: {
            data: ['Forest', 'Steppe', 'Desert', 'Wetland']
          },
          toolbox: {
            show: true,
            orient: 'vertical',
            left: 'right',
            top: 'center',
            feature: {
              mark: {
                show: true
              },
              dataView: {
                show: true,
                readOnly: false
              },
              magicType: {
                show: true,
                type: ['line', 'bar', 'stack', 'tiled']
              },
              restore: {
                show: true
              },
              saveAsImage: {
                show: true
              }
            }
          },
          xAxis: [{
            type: 'category',
            axisTick: {
              show: false
            },
            data: xAxis
          }],
          yAxis: [{
            type: 'value',
            axisLabel: {
              formatter: '{value} 升'
            }
          }],
          series: [{
            name: '总量',
            type: 'bar',
            barGap: 0,
            label: labelOption,
            data: series_consume_channel
          }
          ]
        })
      }


    },
    // 化料统计-配方
    async statistics_chemicals_recipe() {
      let data = {}
      data = {
        sn: this.searchData.sn,
        start: this.searchData.start,
        end: this.searchData.end,
      }
      let res = await this.$http.post('statistics/chemicals-recipe', data)
      if (res.data.code !== 2000) {
        console.log('异常-statistics_chemicals_recipe', res.data)
        this.$message({
          message: res.data.msg,
          type: 'warning'
        });
        return
      }
      let list = []
      res = res.data.data
      for (let i = 0; i < 11; i++) {
        // console.log(res[i])
        if (i + 1 === 11) {
          res[i + 1].id = this.fun.getLanguageText(this.$store.state.language, '总计')
        } else {
          res[i + 1].id = i + 1
          // res[i + 1][]
        }
        list.push(res[i + 1])
      }
      this.chemicals = list
      console.log(list)
      let xAxis = {
        1: [],
        2: [],
        3: [],
        4: []
      }
      let series_count = {
        1: [],
        2: [],
        3: [],
        4: []
      }
      let series_consume_channel = {
        1: [],
        2: [],
        3: [],
        4: []
      }
      for (let i = 1; i < 5; i++) {

        list.map(item => {
          if (item.id !== this.fun.getLanguageText(this.$store.state.language, '总计')) {
            series_count[i].push(item[i].count)
            series_consume_channel[i].push(item[i].consume_channel)
          }

        })
        list.map(item => {
          if (item.id === this.fun.getLanguageText(this.$store.state.language, '总计')) {
            // xAxis[i].push(item.id)
          } else {
            xAxis[i].push(item.medicamentName)
          }
        })
      }
      console.log(xAxis)
      console.log(series_count)
      console.log(series_consume_channel)
      let chemicals_chart_times
      let chemicals_chart_total
      chemicals_chart_times = echarts.init(document.getElementById("chemicals_chart_times"));
      chemicals_chart_total = echarts.init(document.getElementById("chemicals_chart_total"));
      chemicals_chart_times.setOption({
        color: ['#53a1d9', '#ea7b2d', '#ada3a4', '#ffbd03', '#6cc7e1', '#f3ab63', '#aec8ee', '#6b9cd4', '#de7872', '#b7d37c', '#145b7d', '#11264f', '#c99979', '#840228', '#7d5886', '#bb505d', '#0c212b', '#494e8f'],
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        legend: {
          data: [this.fun.getLanguageText(this.$store.state.language, '洗衣仓一'), this.fun.getLanguageText(this.$store.state.language, '洗衣仓二'), this.fun.getLanguageText(this.$store.state.language, '洗衣仓三'), this.fun.getLanguageText(this.$store.state.language, '洗衣仓四')],
        },
        xAxis: [{
          type: 'category',

          data: xAxis[1]
        }],
        yAxis: [{
          type: 'value',
          axisLabel: {
            formatter: '{value} ' + this.fun.getLanguageText(this.$store.state.language, '次'),
          }
        }],
        series: [
          {
            name: this.fun.getLanguageText(this.$store.state.language, '洗衣仓一'),
            type: 'bar',
            data: series_count[1]
          },
          {
            name: this.fun.getLanguageText(this.$store.state.language, '洗衣仓二'),
            type: 'bar',
            data: series_count[2]
          },
          {
            name: this.fun.getLanguageText(this.$store.state.language, '洗衣仓三'),
            type: 'bar',
            data: series_count[3]
          },
          {
            name: this.fun.getLanguageText(this.$store.state.language, '洗衣仓四'),
            type: 'bar',
            data: series_count[4]
          }
        ]
      })
      chemicals_chart_total.setOption({
        color: ['#53a1d9', '#ea7b2d', '#ada3a4', '#ffbd03', '#f3ab63', '#aec8ee', '#6b9cd4', '#de7872', '#b7d37c', '#11264f', '#c99979', '#840228', '#7d5886', '#bb505d', '#0c212b', '#494e8f'],
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        legend: {
          data: [this.fun.getLanguageText(this.$store.state.language, '洗衣仓一'), this.fun.getLanguageText(this.$store.state.language, '洗衣仓二'), this.fun.getLanguageText(this.$store.state.language, '洗衣仓三'), this.fun.getLanguageText(this.$store.state.language, '洗衣仓四')],
        },
        toolbox: {
          show: true,
          orient: 'vertical',
          left: 'right',
          top: 'center',
          feature: {
            mark: {
              show: true
            },
            dataView: {
              show: true,
              readOnly: false
            },
            restore: {
              show: true
            },
            saveAsImage: {
              show: true
            }
          }
        },
        xAxis: [{
          type: 'category',
          axisTick: {
            show: false
          },
          data: xAxis[1]
        }],
        yAxis: [{
          type: 'value',
          axisLabel: {
            formatter: '{value} ' + this.fun.getLanguageText(this.$store.state.language, '升')
          }
        }],
        series: [
          //     {
          //   name: '次数',
          //   type: 'bar',
          //   barGap: 0,
          //   label: labelOption,
          //   data: series_count
          // },
          {
            name: this.fun.getLanguageText(this.$store.state.language, '洗衣仓一'),
            type: 'bar',
            barGap: 0,
            label: labelOption,
            data: series_consume_channel[1]
          },
          {
            name: this.fun.getLanguageText(this.$store.state.language, '洗衣仓二'),
            type: 'bar',
            label: labelOption,
            data: series_consume_channel[2]
          },
          {
            name: this.fun.getLanguageText(this.$store.state.language, '洗衣仓三'),
            type: 'bar',
            label: labelOption,
            data: series_consume_channel[3]
          },
          {
            name: this.fun.getLanguageText(this.$store.state.language, '洗衣仓四'),
            type: 'bar',
            label: labelOption,
            data: series_consume_channel[4]
          }
        ]
      })
      return
      for (let i = 1; i < 5; i++) {
        let xAxis = []
        let series_count = []
        let series_consume_channel = []
        list.map(item => {
          if (item.id === "总计") {
            // xAxis.push(item.id)
          } else {
            series_count.push(item[i].count)
            series_consume_channel.push(item[i].consume_channel)
          }

        })
        list.map(item => {
          if (item.id === "总计") {
            // xAxis.push(item.id)
          } else {
            xAxis.push(item.medicamentName)
          }
        })
        this.chemicals_chart[i] = echarts.init(document.getElementById(`chemicals_chart${i}`));
        this.chemicals_chart_1[i] = echarts.init(document.getElementById(`chemicals_chart_1${i}`));
        // console.log(series_count,xAxis,`chemicals_chart${i}`,document.getElementById(`chemicals_chart${i}`))
        this.chemicals_chart[i].setOption({
          color: ['#33FF00'],
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow'
            }
          },
          legend: {
            data: ['Forest', 'Steppe', 'Desert', 'Wetland']
          },
          toolbox: {
            show: true,
            orient: 'vertical',
            left: 'right',
            top: 'center',
            feature: {
              mark: {
                show: true
              },
              dataView: {
                show: true,
                readOnly: false
              },
              magicType: {
                show: true,
                type: ['line', 'bar', 'stack', 'tiled']
              },
              restore: {
                show: true
              },
              saveAsImage: {
                show: true
              }
            }
          },
          xAxis: [{
            type: 'category',
            axisTick: {
              show: false
            },
            data: xAxis
          }],
          yAxis: [{
            type: 'value',
            axisLabel: {
              formatter: '{value} 次'
            }
          }],
          series: [{
            name: '次数',
            type: 'bar',
            barGap: 0,
            label: labelOption,
            data: series_count
          }
          ]
        })
        this.chemicals_chart_1[i].setOption({
          color: ['#FFFF00'],
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow'
            }
          },
          legend: {
            data: ['Forest', 'Steppe', 'Desert', 'Wetland']
          },
          toolbox: {
            show: true,
            orient: 'vertical',
            left: 'right',
            top: 'center',
            feature: {
              mark: {
                show: true
              },
              dataView: {
                show: true,
                readOnly: false
              },
              magicType: {
                show: true,
                type: ['line', 'bar', 'stack', 'tiled']
              },
              restore: {
                show: true
              },
              saveAsImage: {
                show: true
              }
            }
          },
          xAxis: [{
            type: 'category',
            axisTick: {
              show: false
            },
            data: xAxis
          }],
          yAxis: [{
            type: 'value',
            axisLabel: {
              formatter: '{value} 升'
            }
          }],
          series: [{
            name: '总量',
            type: 'bar',
            barGap: 0,
            label: labelOption,
            data: series_consume_channel
          }
          ]
        })
      }


    },
    // 配方统计
    async statistics_formula() {
      let data = {}
      data = {
        sn: this.searchData.sn,
        start: this.searchData.start,
        end: this.searchData.end,
      }
      let res = await this.$http.post('statistics/formula', data)
      if (res.data.code !== 2000) {
        console.log('异常-statistics_formula', res.data)
        this.$message({
          message: res.data.msg,
          type: 'warning'
        });
        return
      }

      let list = []
      let list_table = []
      res = res.data.data
      for (let i = 0; i < 15; i++) {
        for (let j = 1; j < 11; j++) {
          res[i + 1][`medicamentName_${j}_total`] = res[i + 1][`medicamentName_${j}_total`] + ""
        }
        if (res[i + 1].recipe_name !== '配方不存在') {
          res[i + 1].numid = i + 1
          list.push(res[i + 1])
          list_table.push(res[i + 1])
        } else {
          list.push({
            err: '配方不存在'
          })
        }
      }
      // list.length = 1
      this.formula = list
      this.formula_table = list_table
      console.log(list)
      let series_times = []
      let xAxis = []
      let series_total = []
      let series_volume = []
      let series_price = []
      let legend = []
      list.map((item) => {
        series_times.push([])
        xAxis.push([])
        series_total.push([])
        series_volume.push([])
        series_price.push([])
        legend.push(item.recipe_name)
      })
      for (let i = 1; i < list.length + 1; i++) {
        let obj = list[i - 1]
        for (let j = 1; j < 11; j++) {
          series_times[i - 1].push(obj[`medicamentName_${j}_times`])
          series_total[i - 1].push(obj[`medicamentName_${j}_total`])
          series_price[i - 1].push(obj[`medicamentName_${j}_totalPrice`])
          series_volume[i - 1].push(obj[`medicamentName_${j}_volume`])
          xAxis[i - 1].push(obj[`medicamentName_${j}`])
        }

      }
      // {
      //   name: '加药次数',
      //       type: 'bar',
      //     barGap: 0,
      //     label: labelOption,
      //     data: series_times[0]
      // }
      let series_times_series = []
      let series_total_series = []
      let series_price_series = []
      series_times.map((item, index) => {
        let obj = {
          name: legend[index],
          type: 'bar',
          barGap: 0,
          label: labelOption,
          data: item
        }
        series_times_series.push(obj)
      })
      series_total.map((item, index) => {
        let obj = {
          name: legend[index],
          type: 'bar',
          barGap: 0,
          label: labelOption,
          data: item
        }
        series_total_series.push(obj)
      })
      series_price.map((item, index) => {
        let obj = {
          name: legend[index],
          type: 'bar',
          barGap: 0,
          label: labelOption,
          data: item
        }
        series_price_series.push(obj)
      })
      console.log(series_times_series)
      let chemicals_formula_1 = echarts.init(document.getElementById(`chemicals_formula_1`));
      chemicals_formula_1.setOption({
        color: ['#f7acbc', '#deab8a', '#817936', '#444693', '#d71345', '#843900', '#bed742', '#121a2a', '#78cdd1', '#009ad6', '#145b7d', '#11264f', '#c99979', '#840228', '#7d5886', '#bb505d', '#0c212b', '#494e8f'],
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        legend: {
          data: legend
        },
        toolbox: {
          show: true,
          orient: 'vertical',
          left: 'right',
          top: 'center',
          feature: {
            mark: {
              show: true
            },
            dataView: {
              show: true,
              readOnly: false
            },
            magicType: {
              show: true,
              type: ['line', 'bar', 'stack', 'tiled']
            },
            restore: {
              show: true
            },
            saveAsImage: {
              show: true
            }
          }
        },
        xAxis: [{
          type: 'category',
          axisTick: {
            show: false
          },
          data: xAxis[0]
        }],
        yAxis: [{
          type: 'value',
          axisLabel: {
            formatter: '{value} ' + this.fun.getLanguageText(this.$store.state.language, '次'),
          }
        }],
        series: series_times_series
      })
      let chemicals_formula_2 = echarts.init(document.getElementById(`chemicals_formula_2`));
      chemicals_formula_2.setOption({
        color: ['#f7acbc', '#deab8a', '#817936', '#444693', '#d71345', '#843900', '#bed742', '#121a2a', '#78cdd1', '#009ad6', '#145b7d', '#11264f', '#c99979', '#840228', '#7d5886', '#bb505d', '#0c212b', '#494e8f'],
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        legend: {
          data: legend
        },
        toolbox: {
          show: true,
          orient: 'vertical',
          left: 'right',
          top: 'center',
          feature: {
            mark: {
              show: true
            },
            dataView: {
              show: true,
              readOnly: false
            },
            magicType: {
              show: true,
              type: ['line', 'bar', 'stack', 'tiled']
            },
            restore: {
              show: true
            },
            saveAsImage: {
              show: true
            }
          }
        },
        xAxis: [{
          type: 'category',
          axisTick: {
            show: false
          },
          data: xAxis[0]
        }],
        yAxis: [{
          type: 'value',
          axisLabel: {
            formatter: '{value} ' + this.fun.getLanguageText(this.$store.state.language, '升')
          }
        }],
        series: series_total_series
      })
      let chemicals_formula_3 = echarts.init(document.getElementById(`chemicals_formula_3`));
      chemicals_formula_3.setOption({
        color: ['#f7acbc', '#deab8a', '#817936', '#444693', '#d71345', '#843900', '#bed742', '#121a2a', '#78cdd1', '#009ad6', '#145b7d', '#11264f', '#c99979', '#840228', '#7d5886', '#bb505d', '#0c212b', '#494e8f'],
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        legend: {
          data: legend
        },
        toolbox: {
          show: true,
          orient: 'vertical',
          left: 'right',
          top: 'center',
          feature: {
            mark: {
              show: true
            },
            dataView: {
              show: true,
              readOnly: false
            },
            magicType: {
              show: true,
              type: ['line', 'bar', 'stack', 'tiled']
            },
            restore: {
              show: true
            },
            saveAsImage: {
              show: true
            }
          }
        },
        xAxis: [{
          type: 'category',
          axisTick: {
            show: false
          },
          data: xAxis[0]
        }],
        yAxis: [{
          type: 'value',
          axisLabel: {
            formatter: '{value} ' + this.fun.getLanguageText(this.$store.state.language, '元')
          }
        }],
        series: series_price_series
      })
      return
    },
    // 设备统计
    async statistics_device() {
      return
      let data = {}
      data = {
        sn: this.searchData.sn,
        start: this.searchData.start,
        end: this.searchData.end,
      }
      let res = await this.$http.post('statistics/device', data)
      if (res.data.code !== 2000) {
        console.log('异常-statistics_device', res.data)
        this.$message({
          message: res.data.msg,
          type: 'warning'
        });
        return
      }
      let list = []
      for (let i = 1; i < 16; i++) {
        list.push(res.data.data[i])
      }
      this.device = list
      this.device_info = res.data.data.medicamentName
    },
    // 参数变革记录
    async getChangeList() {
      let res = await this.$http.post('record/parameter-change', {
        search: this.searchData.sn,
        start: this.searchData.start,
        end: this.searchData.end,
      })
      if (res.data.code !== 2000) {
        console.log('异常', res.data)
        return
      }
      this.changeData = res.data.data
      this.changeData.forEach((item, index) => {
        item.id = index + 1
      })
      console.log(res)
    },
    // 时间被改变
    change(e) {
      this.searchData.start = parseInt((e[0]).valueOf() / 1000)
      this.searchData.end = parseInt((e[1]).valueOf() / 1000)
      this.search()
    },
    // 计算高度
    getListHeight() {
      let Html = document.getElementsByClassName('box-card')
      if (Html.length > 0) {
        this.$store.state.clientHeight = Html[0].clientHeight - 131 - 60
      }
    },
    // 参数变更记录下载
    async getRecordExcel() {
      let res = await this.$http.post('record/excel', {
        search: this.searchData.sn,
        start: this.searchData.start,
        end: this.searchData.end,
      })
      if (res.data.code !== 2000) {
        this.$message({
          message: res.data.msg,
          type: 'warning'
        });
        return
      }
      console.log(res)
      if (res.data.data) {
        window.open(res.data.data)
        return
      }
      this.$message({
        message: "下载地址异常请联系技术人员解决",
        type: 'warning'
      });

    },
    // 下载报表
    handleDownload() {

      console.log(this.activeName)
      import("@/assets/js/Export2Excel").then((excel) => {
        let header = [], list = [], type = ''
        if (this.activeName === 'cost') {
          // type = this.fun.getLanguageText(this.$store.state.language, "成本统计");
          type = this.$store.state.language == 1?"成本统计" :"Cost Report"
          header = [
            [this.fun.getLanguageText(this.$store.state.language, "化料编号"), this.fun.getLanguageText(this.$store.state.language, '化料名称'), this.fun.getLanguageText(this.$store.state.language, "总计(升)"), this.fun.getLanguageText(this.$store.state.language, "价格RMB/升"), this.fun.getLanguageText(this.$store.state.language, "总价")],
          ];
          list = [];
          this.cost.map(item => {
            if (item.medicamentName === undefined) {
              item.medicamentName = ''
            }
            let list_item = [item.id + "", item.medicamentName + "", item.consume + "", item.price + "", item.total + ""]
            list.push(list_item)
          })
        } else if (this.activeName === 'chemicals') {
          // type = this.fun.getLanguageText(this.$store.state.language, "化料统计");
          type = this.$store.state.language == 1?"化料统计" :"Chemical Report"
          header = [
            [this.fun.getLanguageText(this.$store.state.language, "化料编号"), this.fun.getLanguageText(this.$store.state.language, "化料名称"), this.fun.getLanguageText(this.$store.state.language, "洗衣龙仓1(次数)"), this.fun.getLanguageText(this.$store.state.language, "洗衣龙仓1(总量/升)"), this.fun.getLanguageText(this.$store.state.language, "洗衣龙仓2(次数)"), this.fun.getLanguageText(this.$store.state.language, "洗衣龙仓2(总量/升)"), this.fun.getLanguageText(this.$store.state.language, "洗衣龙仓3(次数)"), this.fun.getLanguageText(this.$store.state.language, "洗衣龙仓3(总量/升)"), this.fun.getLanguageText(this.$store.state.language, "洗衣龙仓4(次数)"), this.fun.getLanguageText(this.$store.state.language, "洗衣龙仓4(总量/升)"), this.fun.getLanguageText(this.$store.state.language, "总次数"), this.fun.getLanguageText(this.$store.state.language, "总计/升")]
            // ["化料编号", "化料名称", "洗衣龙仓1(次数)", "洗衣龙仓1(总量/升)", "洗衣龙仓2(次数)", "洗衣龙仓2(总量/升)", "洗衣龙仓3(次数)", "洗衣龙仓3(总量/升)", "洗衣龙仓4(次数)", "洗衣龙仓4(总量/升)", "总次数", "总计/升"],
          ]
          list = [];
          this.chemicals.map(item => {
            if (item.medicamentName === undefined) {
              item.medicamentName = ''
            }
            let list_item = [item.id + "", item.medicamentName + "", item[1].count + "", item[1].consume_channel + "", item[2].count + "", item[2].consume_channel + "", item[3].count + "", item[3].consume_channel + "", item[4].count + "", item[4].consume_channel + "", item.line_count + "", item.consume_channel + ""]
            list.push(list_item)
          })
        } else if (this.activeName === 'formula') {
          // type = "配方统计";
          // type = this.fun.getLanguageText(this.$store.state.language, "配方统计");
          type = this.$store.state.language == 1?"配方统计" :"Formula Report"
          header = []
          list = [];
          console.log("this.formula",this.formula)
          this.formula.map((item, index) => {
            let list_item
            if (item.err) {
              list_item = [
                // [`配方${index + 1}`, "不存在"],
                [this.fun.getLanguageText(this.$store.state.language, `配方${index + 1}`), this.fun.getLanguageText(this.$store.state.language, `不存在`)]
                [null],
                [null]
              ]
            } else {
              list_item = [

                [this.fun.getLanguageText(this.$store.state.language, `配方${index + 1}`), item.recipe_name],
                [this.fun.getLanguageText(this.$store.state.language, "已执行次数"), item.recipe_run_times],
                [this.fun.getLanguageText(this.$store.state.language, "洗衣龙"), this.fun.getLanguageText(this.$store.state.language, "洗衣龙1"), null, null, this.fun.getLanguageText(this.$store.state.language, "洗衣龙2"), null, null, this.fun.getLanguageText(this.$store.state.language, "洗衣龙3"), null, this.fun.getLanguageText(this.$store.state.language, "洗衣龙4"), null],
                [this.fun.getLanguageText(this.$store.state.language, "化料名称"), item.medicamentName_1, item.medicamentName_2, item.medicamentName_3, item.medicamentName_4, item.medicamentName_5, item.medicamentName_6, item.medicamentName_7, item.medicamentName_8, item.medicamentName_9, item.medicamentName_10],
                [this.fun.getLanguageText(this.$store.state.language, "加药量设置(毫升)"), item.medicamentName_1_volume, item.medicamentName_2_volume, item.medicamentName_3_volume, item.medicamentName_4_volume, item.medicamentName_5_volume, item.medicamentName_6_volume, item.medicamentName_7_volume, item.medicamentName_8_volume, item.medicamentName_9_volume, item.medicamentName_10_volume],
                [this.fun.getLanguageText(this.$store.state.language, "加药次数"), item.medicamentName_1_times.toString(), item.medicamentName_2_times.toString(), item.medicamentName_3_times.toString(), item.medicamentName_4_times.toString(), item.medicamentName_5_times.toString(), item.medicamentName_6_times.toString(), item.medicamentName_7_times.toString(), item.medicamentName_8_times.toString(), item.medicamentName_9_times.toString(), item.medicamentName_10_times.toString()],
                [this.fun.getLanguageText(this.$store.state.language, "加药总量(升)"), item.medicamentName_1_total, item.medicamentName_2_total, item.medicamentName_3_total, item.medicamentName_4_total, item.medicamentName_5_total, item.medicamentName_6_total, item.medicamentName_7_total, item.medicamentName_8_total, item.medicamentName_9_total, item.medicamentName_10_total],
                [this.fun.getLanguageText(this.$store.state.language, "单价"), item.medicamentName_1_price, item.medicamentName_2_price, item.medicamentName_3_price, item.medicamentName_4_price, item.medicamentName_5_price, item.medicamentName_6_price, item.medicamentName_7_price, item.medicamentName_8_price, item.medicamentName_9_price, item.medicamentName_10_price],
                [this.fun.getLanguageText(this.$store.state.language, "总价"), item.medicamentName_1_totalPrice, item.medicamentName_2_totalPrice, item.medicamentName_3_totalPrice, item.medicamentName_4_totalPrice, item.medicamentName_5_totalPrice, item.medicamentName_6_totalPrice, item.medicamentName_7_totalPrice, item.medicamentName_8_totalPrice, item.medicamentName_9_totalPrice, item.medicamentName_10_totalPrice],

                [null],
                [null]
              ]
            }
            list.push(...list_item)
          })
        } else if (this.activeName === 'warning') {
          // type = "报警信息";
          // type = this.fun.getLanguageText(this.$store.state.language, "报警信息");
          type = this.$store.state.language == 1?"报警信息" :"Alarm Report"
          header = []
          list = [
            [this.fun.getLanguageText(this.$store.state.language, "报警信息种类"), this.fun.getLanguageText(this.$store.state.language, "洗衣龙仓1"), this.fun.getLanguageText(this.$store.state.language, "洗衣龙仓2"), this.fun.getLanguageText(this.$store.state.language, "洗衣龙仓3"), this.fun.getLanguageText(this.$store.state.language, "洗衣龙仓4")],
            [this.warnData[0]['name'], this.warnData[0][1], this.warnData[0][2], this.warnData[0][3], this.warnData[0][4]],
            [this.warnData[1]['name'], this.warnData[1][1], this.warnData[1][2], this.warnData[1][3], this.warnData[1][4]],
            [this.warnData[2]['name'], this.warnData[2][1], this.warnData[2][2], this.warnData[2][3], this.warnData[2][4]],
          ];
        } else if (this.activeName === 'device') {
          this.$message({
            message: this.fun.getLanguageText(this.$store.state.language, "设备统计不支持导出报表"),
            type: 'warning'
          });
          return
        } else if (this.activeName === "change") {
          console.log("导出报表----参数变更记录")
          this.getRecordExcel()
          return
        } else {
          this.$message.error("暂不支持此条数据导出")
          return
        }
        let start = new Date(this.searchData.start * 1000)
        let end = new Date(this.searchData.end * 1000)
        console.log(this.systemInfo)
        let aoa = [
          [null, null, null, null, null],
          [this.fun.getLanguageText(this.$store.state.language, "产品型号"), this.systemInfo.model, null, null, null],
          [this.fun.getLanguageText(this.$store.state.language, "产品名称"), this.systemInfo.name, null, null, null],
          [null, null, null, null, null],
          [this.fun.getLanguageText(this.$store.state.language, "用户名") + `:${localStorage.getItem('username')}`, `Report From: ${start.getFullYear()}-${start.getMonth() + 1}-${start.getDate()}`, null, null, null],
          [this.fun.getLanguageText(this.$store.state.language, "公司名称") + `:${this.company}`, `To: ${end.getFullYear()}-${end.getMonth() + 1}-${end.getDate()}`, null, null, null],
          [this.fun.getLanguageText(this.$store.state.language, "机器序列号") + `:${this.searchData.sn}`, null, null, null, null],
          [null, null, null, null, null],
          [type],
          [null, null, null, null, null],
          ...header,
          ...list,
          [null, null, null, null, null],
          // ["tw-2000", null, null, null, null],
          // ["P/N:型号", null, null, null, null],
          // [null, null, null, null, null],
          [`Copyright © ${new Date().getFullYear()} Hydro Systems`, null, null, null, null],
          [null, null, null, null, null]
        ];
        if (this.activeName === 'warning') {
          aoa = [
            [null, null, null, null, null],
            [this.fun.getLanguageText(this.$store.state.language, "产品型号"), this.systemInfo.model, null, null, null],
            [this.fun.getLanguageText(this.$store.state.language, "产品名称"), this.systemInfo.name, null, null, null],
            [null, null, null, null, null],
            [this.fun.getLanguageText(this.$store.state.language, "用户名") + `:${localStorage.getItem('username')}`, `Report From: ${start.getFullYear()}-${start.getMonth() + 1}-${start.getDate()}`, null, null, null],
            [this.fun.getLanguageText(this.$store.state.language, "公司名称") + `:${this.company}`, `To: ${end.getFullYear()}-${end.getMonth() + 1}-${end.getDate()}`, null, null, null],
            [this.fun.getLanguageText(this.$store.state.language, "机器序列号") + `:${this.searchData.sn}`, null, null, null, null],
            [null, null, null, null, null],
            [this.fun.getLanguageText(this.$store.state.language, "报警统计")],
            // [this.fun.getLanguageText(this.$store.state.language, "报警信息统计时间"), this.fun.getLanguageText(this.$store.state.language, "从"), `${start.getFullYear()}-${start.getMonth() + 1}-${start.getDate()}`, this.fun.getLanguageText(this.$store.state.language, "至"), `${end.getFullYear()}-${end.getMonth() + 1}-${end.getDate()}`],
            // [null, null, null, null, null],
            // [this.fun.getLanguageText(this.$store.state.language, "报警总次数"), this.fun.getLanguageText(this.$store.state.language, "未知")],
            [null, null, null, null, null],
            ...header,
            ...list,
            // [this.fun.getLanguageText(this.$store.state.language, "系统维保"), this.fun.getLanguageText(this.$store.state.language, "未知")],
            // [this.fun.getLanguageText(this.$store.state.language, "配发导入"), this.fun.getLanguageText(this.$store.state.language, "未知")],
            [null, null, null, null, null],
            [`Copyright © ${new Date().getFullYear()} Hydro Systems`, null, null, null, null],
          ];
        }
        // systemInfo
        // if (this.activeName === 'formula') {
        //   aoa = [
        //     ["产品型号:",],
        //     [null, null, null, null, null],
        //     ["报警总次数", "未知"],
        //     [null, null, null, null, null],
        //     ...header,
        //     ...list,
        //     ['系统维保', '未知'],
        //     ['配发导入', '未知'],
        //     [null, null, null, null, null],
        //   ];
        // }
        excel.createEXCEL(aoa, type);
      });
    },
  },
  mounted() {
    this.getListHeight()
    window.onresize = () => {
      return (() => {
        this.getListHeight()
      })();
    }
  },
  destroyed() {
    window.onresize = null;
  },
  watch: {
    chemicals_value(e) {
      if (e === this.fun.getLanguageText(this.$store.state.language, '时间')) {
        this.statistics_chemicals()
      }
      if (e === this.fun.getLanguageText(this.$store.state.language, '配方')) {
        this.statistics_chemicals_recipe()
      }
    },
    cost_value(e) {
      console.log(e)
      if (e === this.fun.getLanguageText(this.$store.state.language, '时间')) {
        this.statistics_cost()
      }
      if (e === this.fun.getLanguageText(this.$store.state.language, '配方')) {
        this.statistics_cost_recipe()
      }
    },
    "searchData.sn"(val) {
      console.log(val)
      if (val) {
        this.$http.post('device/device-company', {
          sn: this.searchData.sn
        }).then(res => {
          this.company = res.data.data.company
        })
      }
    }
  }
};
</script>
<style lang="less">
.el-table thead.is-group th {
  background-color: #FFFFFF;
}

.formula {
  .el-table__empty-block>span {
    color: #FFFFFF;
  }
}
</style>

<style lang="less" scoped>
.container {
  position: relative;
}

.el-input {
  width: 240px;
  margin-right: 10px;
}

.el-select {
  width: 180px;
  margin-left: 20px;
}

.time {
  margin-left: 20px;
  width: 260px;
}

.container {
  display: flex;
  flex-direction: column;
}

.top {
  // height: 32px;
  margin-bottom: 20px;
  position: relative;
}

.add-username {
  position: absolute;
  right: 0;
}

.footer {
  display: flex;
  padding: 0;
  margin-top: 14px;
  justify-content: flex-end;
}

.state-item {
  width: 400px;
  height: 350px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  font-size: 14px;
  border: 1px solid #BBBBBB;
  // justify-content: flex-start;
  // align-items: flex-start;

  .item-title {

    width: 100%;
    height: 54px;
    background-color: #E1F0F9;
    color: #000000;
    font-size: 16px;
    font-family: SourceHanSansSC-bold;
    text-align: center;
    line-height: 54px;

  }

  .item-1 {
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
    text-align: center;
  }

  .item-3 {
    width: 33.333%;
    height: 40px;
    text-align: center;
    word-wrap: break-word;
    // line-height: 40px;
  }

  .item-2 {
    box-sizing: border-box;
    width: 50%;
    height: 34px;
    text-align: center;
    line-height: 34px;
    border: 1px solid rgba(240, 240, 240, 100);
  }
}

.export {
  margin-top: 20px;
}

div {
  box-sizing: border-box;
}

.device {


  div {
    text-align: center;
  }

  .header {
    color: #909399;
    width: 100%;
    display: flex;
    flex-direction: row;
    border: 1px solid rgb(235, 238, 245);

    >div {
      display: flex;
      flex-direction: column;
      flex: 1;

      >div {
        display: flex;
        flex-direction: row;
        // border-bottom: 1px solid #888888;

        >div {
          flex: 1;
          border-right: 1px solid rgb(235, 238, 245);
          padding: 12px 0;
        }
      }
    }


    .header-c1+.header-c1 {
      border-left: 1px solid rgb(235, 238, 245);
    }
  }

  .table {
    display: flex;
    flex-direction: row;
    width: 100%;
    border-left: 1px solid rgb(235, 238, 245);

    .table-c1 {
      display: flex;
      flex-direction: column;
      flex: 1;
      border-right: 1px solid rgb(235, 238, 245);

    }

    .table-c2 {
      padding: 12px 0;
      border-bottom: 1px solid rgb(235, 238, 245);
    }
  }
}

.table-scroll {
  overflow-x: hidden;
  overflow-y: scroll;
}

.table-scroll::-webkit-scrollbar {
  display: none;
  /*隐藏滚动条*/
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 0;
}

.ab {
  //position: ;
  // color: #888888;
}
</style>
